
import { computed, defineComponent, ref, unref, toRefs, onMounted } from 'vue'
import BaseModal from '@/components/base/BaseModal.vue'
import BaseInput from '@/components/base/BaseInput.vue'
import { useToggle } from '@/composables/useToggle'
import { useContentPack } from '@/composables/contentPack/useContentPack'
import { useManybot } from '@/composables/manybot/useManybot'
import localforage from 'localforage'
import { useRouter } from 'vue-router'
import BaseContentEditable from '../base/BaseContentEditable.vue'

export default defineComponent({
  components: { BaseModal, BaseInput, BaseContentEditable },

  props: {
    task: {
      type: String,
      required: true
    },
    target: {
      type: String,
      required: true
    },
  },

  setup (props) {
    const { task, target } = toRefs(props)
    const isWorking = ref(false)
    const router = useRouter()

    const topic = ref('')

    const {
      isActive: open,
      setInactive: close
    } = useToggle(false)

    const {
      currentHeadlineContentPack: contentPackage
    } = useContentPack()

    const {
      taskOptions,
      inputOptions,
      initOptions
    } = useManybot()

    const hasCredit = computed(() => {
      return unref(contentPackage) && unref(contentPackage).used < unref(contentPackage).limit
    })

    const isValidTopic = computed(() => {
      return unref(topic).trim().length >= 3
    })

    onMounted(() => {
      initOptions({ task: task.value, target: target.value })
    })

    const handleFormSubmit = async () => {
      isWorking.value = true

      const currentOptions = taskOptions.value
        .reduce((previous: any, current) => {
          if (current.value) {
            return {
              ...previous,
              [current.key]: current.value
            }
          }
          return previous
        }, {})

      const payload = {
        task: task.value,
        target: target.value,
        topic: topic.value,
        ...currentOptions
      }

      await localforage.setItem('currentChatMessage', payload)

      isWorking.value = false

      close()
      
      router.replace({ name: 'chat-manybot' })
    }

    return {
      open,
      close,
      topic,
      isValidTopic,
      hasCredit,
      isWorking,
      options: taskOptions,
      inputOptions,
      handleFormSubmit,
    }
  }
})
