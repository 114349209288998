<template>
  <div>
    <div class="parent">
      <div class="child" v-if="prefix">
        <span class="editable text-blue-800 font-semibold mr-1">{{ prefix }} </span>
      </div>
      <div class="child">
        <span
          ref="contenteditable"
          class="editable"
          contenteditable
          :data-placeholder="placeholder"
          v-once
          v-text="modelValue"
          @input="update"
        >
        </span>
      </div>
    </div>
  </div>

  <!-- <div class="parent">
    <div class="child">
      <span class="editable text-blue-800 font-semibold">primeiro primeiro primeiro primeiro </span>
    </div>
    <div class="child">
      <span class="editable" contenteditable>segundo segundo segundo segundo </span>
    </div>
    <div class="child">
      <span class="editable" contenteditable data-placeholder="placeholder">terceiro</span>
    </div>
  </div> -->
</template>

<script lang="ts">
import { defineComponent, watch, ref, onMounted } from 'vue'

export default defineComponent({
  props: {
    prefix: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    modelValue: {
      type: String,
      default: '',
    },
  },

  emits: ['update:modelValue'],

  setup (props, {emit}) {
    const contenteditable = ref()

    watch(() => props.modelValue, (v) => {
      if (document.activeElement === contenteditable.value) {
        return
      }
      contenteditable.value.innerHTML = v
    })

    const update = (e: any) => {
      emit('update:modelValue', e.target.textContent)
    }

    onMounted(() => {
      contenteditable.value.focus()
    })

    return {
      contenteditable,
      update, 
    }
  }
})
</script>

<style>
.parent {
  display: inline-block;
  width: 100%;
  word-break: break-all;
  text-align: justify;
}
.child {
  display: inline;
}
.editable {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.editable:empty {
  display: inline-block;
  min-width: 134px;
}
.editable:focus-visible {
  outline: 0;
}
.editable:empty::before {
  content: attr(data-placeholder);
  opacity: 0.4;
}
.editable:empty:focus::before {
  content: attr(data-placeholder);
}
</style>