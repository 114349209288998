
import DashboardContent from '@/components/dashboard/DashboardContent.vue'
import ToolsCardHeader from '@/components/tools/ToolsCardHeader.vue'
import CreatePostButton from '@/components/posts/myPosts/CreatePostButton.vue'
import { defineComponent } from 'vue'
import ManybotInputModal from '@/components/manyBot/ManybotInputModal.vue'
import { useTools } from '@/composables/tools/useTools'

export default defineComponent({
  components: { DashboardContent, ToolsCardHeader, CreatePostButton, ManybotInputModal },
  setup () {
    const { toolsList } = useTools()

    return {
      toolsList
    }
  }
})
