<template>
  <base-modal
    v-model:open="open"
    hide-footer
    size="medium"
  >
    <template v-slot:button>
      <slot name="button-content">
        <button
          class="btn w-full border-none justify-start shadow-none hover:shadow-none bg-transparent hover:bg-gray-100 whitespace-nowrap"
        >
          Open
        </button>
      </slot>
    </template>

    <div class="card-header pt-4">
      <div class="my-4 bg-white">
        <base-content-editable v-model="topic" :prefix="inputOptions.prefix" :placeholder="inputOptions.placeholder" />
      </div>
    </div>
    <div class="card-body pt-4 border-t-1 border-gray-200 bg-gray-100">
      <div class="flex flex-col gap-2">
        <div v-for="option in options" :key="option.key">
          <base-input
            :label="$t(option.label)"
            type="text"
            :placeholder="$t(option.placeholder)"
            v-model=option.value
          />
        </div>
      </div>
    </div>
    <div class="card-footer flex justify-end bg-gray-100">
      <button
        class="btn btn-primary whitespace-nowrap"
        type="button"
        :disabled="!isValidTopic || !hasCredit"
        @click.prevent="handleFormSubmit"
      >
        {{ $t('action.continue') }}
        <i class="fas fa-spinner fa-spin !mr-0 ml-4" v-if="isWorking"></i>
        <i class="fas fa-arrow-right !mr-0 ml-4" v-else></i>
      </button>
    </div>
  </base-modal>
</template>

<script lang="ts">
import { computed, defineComponent, ref, unref, toRefs, onMounted } from 'vue'
import BaseModal from '@/components/base/BaseModal.vue'
import BaseInput from '@/components/base/BaseInput.vue'
import { useToggle } from '@/composables/useToggle'
import { useContentPack } from '@/composables/contentPack/useContentPack'
import { useManybot } from '@/composables/manybot/useManybot'
import localforage from 'localforage'
import { useRouter } from 'vue-router'
import BaseContentEditable from '../base/BaseContentEditable.vue'

export default defineComponent({
  components: { BaseModal, BaseInput, BaseContentEditable },

  props: {
    task: {
      type: String,
      required: true
    },
    target: {
      type: String,
      required: true
    },
  },

  setup (props) {
    const { task, target } = toRefs(props)
    const isWorking = ref(false)
    const router = useRouter()

    const topic = ref('')

    const {
      isActive: open,
      setInactive: close
    } = useToggle(false)

    const {
      currentHeadlineContentPack: contentPackage
    } = useContentPack()

    const {
      taskOptions,
      inputOptions,
      initOptions
    } = useManybot()

    const hasCredit = computed(() => {
      return unref(contentPackage) && unref(contentPackage).used < unref(contentPackage).limit
    })

    const isValidTopic = computed(() => {
      return unref(topic).trim().length >= 3
    })

    onMounted(() => {
      initOptions({ task: task.value, target: target.value })
    })

    const handleFormSubmit = async () => {
      isWorking.value = true

      const currentOptions = taskOptions.value
        .reduce((previous: any, current) => {
          if (current.value) {
            return {
              ...previous,
              [current.key]: current.value
            }
          }
          return previous
        }, {})

      const payload = {
        task: task.value,
        target: target.value,
        topic: topic.value,
        ...currentOptions
      }

      await localforage.setItem('currentChatMessage', payload)

      isWorking.value = false

      close()
      
      router.replace({ name: 'chat-manybot' })
    }

    return {
      open,
      close,
      topic,
      isValidTopic,
      hasCredit,
      isWorking,
      options: taskOptions,
      inputOptions,
      handleFormSubmit,
    }
  }
})
</script>