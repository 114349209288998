type ToolProp = {
  icon: string
  title: string
  description: string
  background: string,
  featured: boolean,
  access: {
    type: 'modal' | 'route'
    task: string
    target: string
    route: string
  }
}

const toolsList: ToolProp[] = [
  {
    icon: 'whatsapp.svg',
    title: 'page.tools.whatsapp_link.title',
    description: 'page.tools.whatsapp_link.description-html',
    background: 'bg-gradient-to-br from-green-400 to-green-800',
    featured: true,
    access: {
      type: 'route',
      task: '',
      target: '',
      route: 'tools-whatsapp-link'
    }
  },
  {
    icon: 'instagram.svg',
    title: 'page.manybot.model.create_social_post.instagram.title',
    description: 'page.manybot.model.create_social_post.instagram.description-html',
    background: 'bg-gradient-to-br from-instagram to-purple-700',
    featured: true,
    access: {
      type: 'modal',
      task: 'CreateSocialPost',
      target: 'instagram',
      route: ''
    }
  },
  {
    icon: 'facebook.svg',
    title: 'page.manybot.model.create_social_post.facebook.title',
    description: 'page.manybot.model.create_social_post.facebook.description-html',
    background: 'bg-gradient-to-br from-facebook to-blue-800',
    featured: false,
    access: {
      type: 'modal',
      task: 'CreateSocialPost',
      target: 'facebook',
      route: ''
    }
  },
  {
    icon: 'linkedin.svg',
    title: 'page.manybot.model.create_social_post.linkedin.title',
    description: 'page.manybot.model.create_social_post.linkedin.description-html',
    background: 'bg-gradient-to-br from-linkedin to-cyan-900',
    featured: false,
    access: {
      type: 'modal',
      task: 'CreateSocialPost',
      target: 'linkedin',
      route: ''
    }
  },
  {
    icon: 'twitter.svg',
    title: 'page.manybot.model.create_social_post.twitter.title',
    description: 'page.manybot.model.create_social_post.twitter.description-html',
    background: 'bg-gradient-to-br from-twitter to-blue-800',
    featured: false,
    access: {
      type: 'modal',
      task: 'CreateSocialPost',
      target: 'twitter',
      route: ''
    }
  },
  {
    icon: 'youtube.svg',
    title: 'page.manybot.model.create_video_script.youtube.title',
    description: 'page.manybot.model.create_video_script.youtube.description-html',
    background: 'bg-gradient-to-br from-red-600 to-orange-900',
    featured: false,
    access: {
      type: 'modal',
      task: 'CreateVideoScript',
      target: 'youtube',
      route: ''
    }
  },
  {
    icon: 'tiktok.svg',
    title: 'page.manybot.model.create_video_script.tiktok.title',
    description: 'page.manybot.model.create_video_script.tiktok.description-html',
    background: 'bg-gradient-to-br from-gray-700 to-black',
    featured: false,
    access: {
      type: 'modal',
      task: 'CreateVideoScript',
      target: 'tiktok',
      route: ''
    }
  },
  {
    icon: 'youtube.svg',
    title: 'page.manybot.model.create_video_script.shorts.title',
    description: 'page.manybot.model.create_video_script.shorts.description-html',
    background: 'bg-gradient-to-br from-red-600 to-orange-900',
    featured: false,
    access: {
      type: 'modal',
      task: 'CreateVideoScript',
      target: 'shorts',
      route: ''
    }
  },
  {
    icon: 'youtube.svg',
    title: 'page.manybot.model.create_headline.youtube.title',
    description: 'page.manybot.model.create_headline.youtube.description-html',
    background: 'bg-gradient-to-br from-red-600 to-orange-900',
    featured: false,
    access: {
      type: 'modal',
      task: 'CreateHeadline',
      target: 'youtube',
      route: ''
    }
  },
  {
    icon: 'instagram.svg',
    title: 'page.manybot.model.create_headline.instagram.title',
    description: 'page.manybot.model.create_headline.instagram.description-html',
    background: 'bg-gradient-to-br from-instagram to-purple-700',
    featured: false,
    access: {
      type: 'modal',
      task: 'CreateHeadline',
      target: 'instagram',
      route: ''
    }
  },
  {
    icon: 'facebook.svg',
    title: 'page.manybot.model.create_headline.facebook.title',
    description: 'page.manybot.model.create_headline.facebook.description-html',
    background: 'bg-gradient-to-br from-facebook to-blue-800',
    featured: false,
    access: {
      type: 'modal',
      task: 'CreateHeadline',
      target: 'facebook',
      route: ''
    }
  },
  {
    icon: 'linkedin.svg',
    title: 'page.manybot.model.create_headline.linkedin.title',
    description: 'page.manybot.model.create_headline.linkedin.description-html',
    background: 'bg-gradient-to-br from-linkedin to-cyan-900',
    featured: false,
    access: {
      type: 'modal',
      task: 'CreateHeadline',
      target: 'linkedin',
      route: ''
    }
  },
  {
    icon: 'wordpress.svg',
    title: 'page.manybot.model.create_headline.blog.title',
    description: 'page.manybot.model.create_headline.blog.description-html',
    background: 'bg-gradient-to-br from-purple-500 to-purple-800',
    featured: false,
    access: {
      type: 'modal',
      task: 'CreateHeadline',
      target: 'blog',
      route: ''
    }
  },
  {
    icon: 'wordpress.svg',
    title: 'page.manybot.model.create_article.blog.title',
    description: 'page.manybot.model.create_article.blog.description-html',
    background: 'bg-gradient-to-br from-purple-500 to-purple-800',
    featured: true,
    access: {
      type: 'modal',
      task: 'CreateBlogArticle',
      target: 'blog',
      route: ''
    }
  },
]

const homeToolsList: ToolProp[] = toolsList.filter(tool => tool.featured)

export const useTools = () => {
  return {
    toolsList,
    homeToolsList
  }
}