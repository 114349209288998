<template>
  <dashboard-content>
    <template v-slot:actions>
      <create-post-button />
    </template>

    <div class="flex flex-col gap-8">
      <div
        class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4"
      >
        <router-link :to="{ name: 'my-posts' }">
          <div class="card card-hover flex flex-col items-start justify-center h-full">
            <tools-card-header
              icon="my-contents.png"
              :title="$t('nav.sidebar.my_contents')"
              bgColor="bg-gradient-to-br from-yellow-400 to-orange-500"
            />
            <div class="card-body pt-4 text-left">
              <p
                v-html="$t('page.posts.my_content_description')"
              />
            </div>
          </div>
        </router-link>

        <!-- <router-link :to="{ name: 'my-ideas' }">
          <div class="card card-hover flex flex-col items-start justify-center h-full">
            <tools-card-header
              icon="my-ideas.png"
              :title="$t('page.posts.my_ideas_title')"
              bgColor="bg-gradient-to-br from-pink-400 to-pink-900"
            />
            <div class="card-body pt-4 text-left">
              <p
                v-html="$t('page.posts.my_ideas_description')"
              />
            </div>
          </div>
        </router-link> -->
      </div>
      
      <div class="mt-8">
        <h2>{{ $t('page.posts.approve_content_tools_title') }}</h2>
        <div class="text-gray-600">{{ $t('page.posts.approve_content_tools_description') }}</div>
      </div>
      <div
        class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4"
      >
        <router-link :to="{ name: 'review-posts' }">
          <div class="card card-hover flex flex-col items-start justify-center h-full">
            <tools-card-header
              icon="personal-social-posts.png"
              :title="$t('page.posts.review_title')"
              bgColor="bg-gradient-to-br from-indigo-400 to-blue-800"
            />
            <i class="absolute fa-2x fa-crown fas right-4 -mt-12 text-yellow-500"></i>
            <div class="card-body pt-4 text-left">
              <p
                v-html="$t('page.posts.review_description')"
              />
            </div>
          </div>
        </router-link>

        <router-link :to="{ name: 'ready-to-use-posts' }">
          <div class="card card-hover flex flex-col items-start justify-center h-full">
            <tools-card-header
              icon="free-social-posts.png"
              :title="$t('page.posts.post_read_to_use_title')"
              bgColor="bg-gradient-to-br from-red-400 to-red-800"
            />
            <div class="card-body pt-4 text-left">
              <p
                v-html="$t('page.posts.post_read_to_use_description')"
              />
            </div>
          </div>
        </router-link>
      </div>

      <div class="mt-8" id="tools">
        <h2>{{ $t('page.posts.tools_title') }}</h2>
        <div class="text-gray-600">{{ $t('page.posts.tools_description') }}</div>
      </div>

      <div
        class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4"
      >
        <!-- <router-link :to="{ name: 'idea-generator' }">
          <div class="card card-hover flex flex-col items-start justify-center h-full">
            <tools-card-header
              icon="title-gererator-ia.png"
              :title="$t('page.posts.idea_generator_title')"
              bgColor="bg-gradient-to-br from-pink-400 to-purple-900"
            />
            <div class="card-body pt-4 text-left">
              <p
                v-html="$t('page.posts.idea_generator_description')"
              />
            </div>
          </div>
        </router-link>

        <router-link :to="{ name: 'content-generator' }">
          <div class="card card-hover flex flex-col items-start justify-center h-full">
            <tools-card-header
              icon="content-generator-ia.png"
              :title="$t('page.posts.content_generator_title')"
              bgColor="bg-gradient-to-br from-red-600 to-gray-800"
            />
            <div class="card-body pt-4 text-left">
              <p
                v-html="$t('page.posts.content_generator_description')"
              />
            </div>
          </div>
        </router-link> -->
        
        <template v-for="(tool, index) in toolsList" :key="index">
          <manybot-input-modal
            v-if="tool.access.type === 'modal'"
            :task="tool.access.task"
            :target="tool.access.target"
          >
            <template v-slot:button-content>
              <div class="card card-hover flex flex-col items-start justify-center h-full">
                <tools-card-header
                  :icon="tool.icon"
                  :title="$t(tool.title)"
                  :bgColor="tool.background"
                />
                <div class="card-body pt-4 text-left">
                  <p v-html="$t(tool.description)" />
                </div>
              </div>
            </template>
          </manybot-input-modal>

          <router-link
            v-if="tool.access.type === 'route'"
            :to="{ name: tool.access.route }"
          >
            <div class="card card-hover flex flex-col items-start justify-center h-full">
              <tools-card-header
                :icon="tool.icon"
                :title="$t(tool.title)"
                :bgColor="tool.background"
              />
              <div class="card-body pt-4 text-left">
                <p v-html="$t(tool.description)" />
              </div>
            </div>
          </router-link>
        </template>
      </div>
    </div>
  </dashboard-content>
</template>

<script lang="ts">
import DashboardContent from '@/components/dashboard/DashboardContent.vue'
import ToolsCardHeader from '@/components/tools/ToolsCardHeader.vue'
import CreatePostButton from '@/components/posts/myPosts/CreatePostButton.vue'
import { defineComponent } from 'vue'
import ManybotInputModal from '@/components/manyBot/ManybotInputModal.vue'
import { useTools } from '@/composables/tools/useTools'

export default defineComponent({
  components: { DashboardContent, ToolsCardHeader, CreatePostButton, ManybotInputModal },
  setup () {
    const { toolsList } = useTools()

    return {
      toolsList
    }
  }
})
</script>

<style scoped>
@layer components {
  a {
    @apply hover:no-underline;
  }
  .fa-crown {
    @apply text-yellow-500 !important;
  }
}
</style>