
import { defineComponent, watch, ref, onMounted } from 'vue'

export default defineComponent({
  props: {
    prefix: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    modelValue: {
      type: String,
      default: '',
    },
  },

  emits: ['update:modelValue'],

  setup (props, {emit}) {
    const contenteditable = ref()

    watch(() => props.modelValue, (v) => {
      if (document.activeElement === contenteditable.value) {
        return
      }
      contenteditable.value.innerHTML = v
    })

    const update = (e: any) => {
      emit('update:modelValue', e.target.textContent)
    }

    onMounted(() => {
      contenteditable.value.focus()
    })

    return {
      contenteditable,
      update, 
    }
  }
})
